/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
/*============================================================== 
 For Laptop & above all (1650px) 
 ============================================================== */

@media(min-width:1650px) {
    .widget-app-columns {
      column-count:3; 
    } 
    .campaign{
        height: 365px!important;
    }
}
/*============================================================== 
 For Laptop & above all (1370px) 
 ============================================================== */

@media(max-width:1370px) {
    .widget-app-columns {
      column-count:2;
    }  
}


/*-- ============================================================== 
 Small Desktop & above all (1024px) 
 ============================================================== */

@media(min-width:1024px) {
   .page-wrapper{
        margin-left:240px;
        
    }  
   .footer{
            left: 240px;
      } 
    
}

@media(max-width:1023px) {
   .page-wrapper{
        margin-left:60px;
        transition: 0.2s ease-in;
    }  
   .footer{
            left: 60px;
      }
    .widget-app-columns {
        column-count:1;
    }
    .inbox-center a {
        width: 200px;
    }
}


/*-- ============================================================== 
 Ipad & above all(768px) 
 ============================================================== */

@media(min-width:768px) {
    .navbar-header {
        width: 240px;
        flex-shrink:0;
        .navbar-brand {
            padding-top: 0px;
        }
    }
    .material-icon-list-demo .icons div {
        width: 33%;
        padding: 15px;
        display: inline-block;
        line-height: 40px;
    }
   .mini-sidebar {
        .page-wrapper{
            margin-left:60px;
        }
        .footer{
            left: 60px;
        }
    }
    .flex-wrap{
        flex-wrap:nowrap!important;
        -webkit-flex-wrap:nowrap!important;
    }
}


/*-- ============================================================== 
 Phone and below ipad(767px) 
 ============================================================== */

@media(max-width:767px) {
    
    /*Header*/
    .topbar {
        position: fixed;
        width: 100%;
        .top-navbar {
            padding-right: 15px;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            flex-direction: row;
            flex-wrap: nowrap;
            -webkit-align-items: center;
            .navbar-collapse {
                display: flex;
                width: 100%;
            }
            .navbar-nav {
                flex-direction: row;
                > .nav-item.show {
                    position: static;
                    .dropdown-menu {
                        width: 100%;
                        margin-top: 0px;
                    }
                }
                > .nav-item > .nav-link {
                    padding-left: .50rem;
                    padding-right: .50rem;
                }
                .dropdown-menu {
                    position: absolute;
                }
            }
        }
    }
    .mega-dropdown {
        .dropdown-menu {
            height: 480px;
            overflow: auto;
        }
    }
    /*Sidebar and wrapper*/
    .mini-sidebar .page-wrapper{
        margin-left:0px;
        padding-top: 70px;
    }
    .comment-text{
        .comment-footer{
            .action-icons {
                display: block;
                padding: 10px 0;
            } 
        }
    }
    
    .vtabs .tabs-vertical {
        width: auto;
    }
    /*Footer*/
    .footer{
            left: 0px;
        }
    .material-icon-list-demo .icons div {
        width: 100%;
    }
    .error-page{
        .footer{
            position: fixed;
            bottom: 0px;
            z-index: 10;
        }
    }
    .error-box{
        position: relative;
        padding-bottom: 60px;
    }
    .error-body{
        padding-top: 10%;
           h1{
              font-size:100px;
              font-weight:600;
              line-height:100px;
           }
        }
    .login-register{
        position: relative;
        overflow: hidden;
    }
    .login-box{
        width: 90%;
        
    }
    .login-sidebar{
        padding: 10% 0;
        .login-box{
            position: relative;
        }
    }
    
    /*This is for chat page*/
    .chat-main-box .chat-left-aside {
        left: -250px;
        position: absolute;
        
        transition: 0.5s ease-in;
        background: $white;
        &.open-pnl {
            left: 0px;
        }
        .open-panel {
            display: block;
        }
    }
    .chat-main-box .chat-right-aside {
        width: 100%;
    }
    /*Timeline*/
    ul.timeline:before {
        left: 40px;
    }
    ul.timeline>li>.timeline-panel {
        width: calc(100% - 90px);
    }
    ul.timeline>li>.timeline-badge {
        top: 16px;
        left: 15px;
        margin-left: 0;
    }
    ul.timeline>li>.timeline-panel {
        float: right;
    }
    ul.timeline>li>.timeline-panel:before {
        right: auto;
        left: -15px;
        border-right-width: 15px;
        border-left-width: 0;
    }
    ul.timeline>li>.timeline-panel:after {
        right: auto;
        left: -14px;
        border-right-width: 14px;
        border-left-width: 0;
    }
    
    /*Contact app page*/
    .left-aside{
        width: 100%;
        position: relative;
        border:0px;
    }
    .right-aside{
        margin-left: 0px;
    }
    .flex-wrap{
        flex-wrap:wrap!important;
        -webkit-flex-wrap:wrap!important;
    }
    .chat-list li .chat-content{
        width: calc(100% - 80px);
    }
}