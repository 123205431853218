/*******************
/*Globals
*******************/

h5{
  margin:0;
}

.padding-top{
  padding-top:1.5em;
}

.padding-top-mini{
  padding-top:0.75em;
}

.padding-bottom{
  padding-bottom:1.5em;
}

.padding-bottom-mini{
  padding-bottom:0.75em;
}

.flex-container{
  display:flex;
  width:100%;
}

.fixed-height{
  @media (min-width: 1024px){
    max-height:50vh;
  }
}

.panel{
  display:none;
  &.active{
    display:block;
  }
}

table{
  width:100%;
  table-layout:fixed;

  .clickable span:before{
    content:'\f0da';
    font-family: "Font Awesome 5 Free";
    display:block;
    width:20px;
    height:20px;
    position: absolute;
    left:-10px;
  }

  td[aria-expanded="true"] span:before{
    content:"\f0d7";
    left:-12px;
  }

  .custom-control{
    min-height:unset;
    margin:0;
  }

  .custom-control-label::before, .custom-control-label::after{
    top:0;
  }

  &.materials{
    margin: 1em 0;
  }

  th{
    color:#99abb4;
    vertical-align: bottom;

    &.action{
      width:5%;
    }
  }

  td{
    padding-top: 10px;

    .data{
      display: inline-block;
      vertical-align: middle;
      width:25%;
      text-align: center;
    }

    .progress{
      margin-left:5px;
      display: inline-block;
      vertical-align: middle;
      width:55%;
    }

    .inline-menu{
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;

      i{
        padding: 4px 10px;
      }
    }
  }

  th, td{
    padding-right: 7px;
    font-size: 0.8rem;
    font-weight: 600;
  }

  .details{
    th,td{
      font-weight:400;
    }
  }
}

.line-success{
  border: 1px solid $success;
}
.line-warning{
  border: 1px solid $warning;
}
.line-danger{
  border: 1px solid $danger;
}

/*******************
/* PROGRESS
/*******************/
ul.meter{
  list-style:none;
  display:flex;
  padding:0;

  li{
    flex:2;
    text-align:center;

    &:last-child{
      text-align:right;
    }

    &:first-child{
      text-align:left;
    }
  }
}

/*******************
/* FILTERS
/*******************/

.filters-group{
  display: flex;
  margin-bottom: 1.5em;

  .filter{
    width: 200px;
    margin-right: 2em;
  }

  .btns-group{
    display: flex;
    align-items: flex-end;
  }
}

.filters-row{
  font-size:0.9rem;
  padding-bottom: 1em;

  label{
    padding: 0 0.5em;
    color: #000;
    font-weight: 500;
  }
  select{
    width: 200px;
    height: 30px;
    color: #727b84;
    border-color: #d7dfe3;

    &:not(:last-child){
      margin-right: 1em;
    }
  }
}

/*******************
/* MAPS
/*******************/
#map{
  width:100%;
  height:100%;
}

/*******************
/*MATERIAL PRO OVERRIDE
*******************/
.progress{
  border-radius:0px;

  span{
    color: #FFF;
    z-index:99;
  }
}
.progress.big{
  height: 23px;
}

.data-progress{
  margin-bottom: 1em;
}

.btn-link{
  color: #2186b6;
}

.btn-primary{
  background-color: #2186b6 !important;
  border-color: #2186b6 !important;
}

label{
  font-size: 0.9em;
}

/*******************
/*SINGLE ITEMS
*******************/
.data-legend{
  margin-top:1em;
}

/*******************
/*Cards
*******************/
.card-group{
  width:100%;
}

.link{
  transition: all .3s;
}

.link:hover{
  transform:scale(1.05);
}


.card-group.separate>.card{
  &:not(:last-child){
    margin-right: 2em;
  }
  &+.card{
    border-left: 1px solid #d7dfe3;
  }
}


.card{

  flex-direction: row;

  .icon_container.dark{
    text-align: center;
    min-width: 160px;
    margin-right: 1em;

    .silot{
      width: 150px;
    }
    .boilers{
      width: 70px;
    }
  }

  .icon_container, .card-infos{
    display:inline-block;
    vertical-align:middle;
    height:100%;

    img{
      height:100%;
      object-fit: contain;
    }

    &.card-infos{
      padding:0.5em 0;
    }

    &.dark{
      background-color: #444444;
    }
  }

  .card-highlight{
    font-size: 1.6em;
    color: #000;
  }

  border-radius:0px !important;

  &.map{
    width:70%;
  }
  &.details-box{
    width:30%;
  }

  .option-menu{
    position: absolute;
    z-index:2;
    right:0;
    padding:1em 0;

    a[data-toggle="dropdown"]{
      cursor:pointer;
      padding:1em;
    }
  }

  .card-head{
    position:relative;

    .card-highlight{
      &:last-child{
        margin:0;
      }
    }

    p.card-subtitle{
      font-weight:500;
      color: $secondary;

      &:last-child{
        margin:0;
      }

      span{
        color:$dark;
      }
    }
  }

  .card-body{
    padding: 0.8rem 1rem;

    h6{
      margin-bottom: 5px;
      color: $muted;
    }

    .level{
      font-size:2.2rem;
    }
    .level-detail{
      font-size: 0.7rem;
      font-weight:500;
    }
  }
}

.floating-img{
  &.corner-right{
    position: absolute;
    right:0;
    bottom:0;
  }
}

/*******************
/*Line charts
*******************/
.lineChart canvas{
  top: -24px !important;
}

.chart1{
  height: 138px;
}
.chart2{
  height: 215px;
}

/*******************
/*Pie charts
*******************/

.pie-charts-group{
  overflow:hidden;
  text-align:center;

  &.big-chart{
    .pie-chart{
      width:140px;
      margin-right: 7%;
    }
  }

  .pie-chart{
    width:20%;
    margin-right:5%;
    text-align:center;
    display:inline-block;

    &:last-of-type{
      margin:0;
    }

    a{
      font-size:0.75rem;
      display:block;
      color:#2b8fe7;
      cursor:pointer;
      line-height:35px;

      span{
        white-space: nowrap;
      }
    }

    a.active{
      font-weight: 600;
      position: relative;
    }
    a.active:after{
      content:'\f0d7';
      font-family: "Font Awesome 5 Free";
      font-size: 1.7em;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }

    input{
      font-weight:normal !important;
    }

    i{
      display:block;
      margin-top:-1em;
    }
  }
}

/*******************
/*SITE OVERVIEW SPECIFIC
*******************/

[data-toggle="collapse"]{
  position: relative;
  cursor:pointer;
}

.grade{
  width: 25px;
  height: 20px;
  vertical-align: middle;
  display: inline-block;
  text-align:center;
  border-radius:5px;
  color: #fff;

  &:not(:last-child){
    margin-right:3px;
  }
}

.map-container{
  width:70%;
}
.details-container{
  height: 565px;
  overflow-y: auto;
  width:30%;
  border-left: 1px solid #e9ecef;
}

.quit{
  float:left;
  font-size: 1.3em;
  cursor:pointer;
  margin-right:1em;

  i{
    vertical-align: middle;
  }
}
