.topbar {
    .navbar-header {
        background-color: $themecolor-alt;

        .navbar-brand > span {
            margin-left: 10px;
        }
    }
}

.topbar .top-navbar .navbar-header .navbar-brand {
    margin-right: 1rem;
    padding-bottom: 5px;
}

/* Notifications */
.btn.btn-circle{
  padding:4px;
  margin-right: 1em;
}
