// Theme Colors
// ==========================================================================
$themecolor: #181c22;
$themecolor-dark: #028ee1;
$topbar: #FFFFFF;
$sidebar:#272c33;
$sidebar-text: #798699;
$sidebar-icons: #798699;
$sidebar-dark: #181c22;
$themecolor-alt: #a31d1d;
$linkcolor: #007bff;


/*bootstrap Color*/
$danger: #c44640 !default;
$success: #85c142 !default;
$warning: #d8b62c !default;


// Custom Media Query Breakpoint
// ==========================================================================
$extra-small-and-up: "only screen and (min-width :560px)";
$small-and-up: "only screen and (min-width :768px)";
$large-and-down: "only screen and (max-width :1200px)";
$extra-extra-large-and-up: "only screen and (min-width : 1501px)";
$extra-large-and-down: "only screen and (max-width : 1500px)";
