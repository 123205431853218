/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/

/*******************
/*Top bar
*******************/

.topbar {
    background: $topbar;

    .top-navbar .navbar-header .navbar-brand .dark-logo{
        display: none;
    }
    .top-navbar .navbar-header .navbar-brand .light-logo{
        display: inline-block;
        color:rgba(255, 255, 255, 0.8);
    }
    .navbar-light .navbar-nav .nav-item > {
        a.nav-link {
            color: #99abb4!important;
            &:hover,
            &:focus {
                color: #85969e!important;
            }
        }
    }
}
.logo-center .topbar {
    .navbar-header {
        background: transparent;
        box-shadow: none;
    }
    .top-navbar .navbar-header .navbar-brand .dark-logo{
        display: none;
    }
    .top-navbar .navbar-header .navbar-brand .light-logo{
        display: inline-block;
        color:rgba(255, 255, 255, 0.8);
    }
}

/*******************
/*General Elements
*******************/
a{
  color:$linkcolor;
}
a.link {
    &:hover,
    &:focus {
        color: $themecolor!important;
    }
}

.bg-theme {
    background-color: $themecolor !important;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
    background-color: $themecolor;
    border-color: $themecolor;
}

.right-sidebar {
    .rpanel-title {
        background: $themecolor;
    }
}

.stylish-table {
    tbody tr {
        &:hover,
        &.active {
            border-left: 4px solid $themecolor;
        }
    }
}
.text-themecolor{
    color:$themecolor!important;
}
.profile-tab,
.customtab {
    li {
        a.nav-link {
            &.active {
                border-bottom: 2px solid $themecolor;
                color: $themecolor;
            }
            &:hover {
                color: $themecolor;
            }
        }
    }
}


/*******************
/*Buttons
*******************/

.btn-themecolor,
.btn-themecolor.disabled {
    background: $themecolor;
    color: $white;
    border: 1px solid $themecolor;
    &:hover {
        background: $themecolor;
        opacity: 0.7;
        border: 1px solid $themecolor;
    }
    &.active,
    &:focus {
        background: $themecolor-dark;
    }
}
#wrapper .login-box .btn-info{
  background-color: $themecolor-alt;
  border-color: $themecolor-alt;

  &:hover{
    background-color: darken($themecolor-alt, 10%)!important;
    border-color: darken($themecolor-alt, 10%)!important;
  }
}

.form-material .form-control.focus, .form-material .form-control:focus {
    background-image: linear-gradient($themecolor-alt,$themecolor-alt),linear-gradient(#d9d9d9,#d9d9d9);
}


/*******************
/*sidebar navigation
*******************/
.card-no-border .left-sidebar, .card-no-border .sidebar-nav{
    background: $sidebar;
}
.mini-sidebar .sidebar-nav{
    background: transparent;
}
@media (min-width: 768px) {
.mini-sidebar .sidebar-nav #sidebarnav>li>ul{
        background: $sidebar-dark;
    }
}
.sidebar-nav {
    background: $sidebar;
    ul {
        li {
            a {
                color: $sidebar-text;
                &.active,
                &:hover {
                    color: $themecolor-alt;
                }
                &.active {
                    color: $white;
                }
            }
            &.nav-small-cap {
                color: $muted;
            }
        }
    }
    > ul > li {
        &.active > a, &.active:hover > a {

            color: $white!important;
            background: $themecolor-alt!important;
            i {
                color: $white!important;
            }
            &:after{
                border-color:$white;
            }
        }
    }
    > ul > li > a {

        &.active,
        &:hover {

            i {
                color: $themecolor-alt;
            }
        }
        i {
            color: $sidebar-icons;
        }
        &.active {
            font-weight: 400;
            background: $white;
            color: $themecolor;
        }
    }
}
.user-profile .profile-text a{
    color: $sidebar-text!important;
}
.card-no-border .sidebar-footer{
    background: $sidebar-dark;
}
